// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-openings-js": () => import("./../../src/pages/openings.js" /* webpackChunkName: "component---src-pages-openings-js" */),
  "component---src-pages-people-js": () => import("./../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-pi-js": () => import("./../../src/pages/pi.js" /* webpackChunkName: "component---src-pages-pi-js" */),
  "component---src-pages-publications-js": () => import("./../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("./../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-teaching-js": () => import("./../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */)
}

